import { Form, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import { PostAuthComponentParams } from '../../types/auth';
import React, { useEffect, useState } from 'react';
import ConsentContainer from '../ConsentContainer';
import { AcceptConsent } from '../AcceptConsent';
import DenyConsent from '../DenyConsent';
import IndetityConsentInfo from '../IdentityConsentInfo';
import { useIntl } from 'react-intl';
import identityConsentMessages from '../../i18n/identityConsent.messages';
import { B2I_ORG_NAMES } from '../../constants/userConsent';
import { postConsent } from '../../services/consent';
import { useNonceGenerator } from '../../contexts/NonceGeneratorContextProvider';

const IdentityConsent = ({
  user,
  onContinue,
  onCancel,
  config,
  setConsetAccepted,
  setError,
}: PostAuthComponentParams): JSX.Element => {
  const { formatMessage } = useIntl();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const nonceGenerator = useNonceGenerator();
  const params = new URLSearchParams(window.location.search);
  const hasB2IOrg = (organizationName: String | undefined) => {
    return Object.values(B2I_ORG_NAMES).includes(
      organizationName as B2I_ORG_NAMES
    );
  };
  const shouldShowConsentPage = () => {
    return (
      config.show_invitation_screen === 'true' &&
      user.invitation !== undefined &&
      user.invitation.learner_data_consent_required &&
      !hasB2IOrg(user.invitation.organization_name)
    );
  };

  useEffect(() => {
    const showConsentPage = shouldShowConsentPage();
    const initiateConsentAccept = async () => {
      setIsLoading(true);
      await postConsent(
        config.gandalfProfileEndpoint,
        true,
        user,
        nonceGenerator,
        params,
        config
      );
      setIsLoading(false);
    };

    if (!showConsentPage) {
      if (hasB2IOrg(user.invitation && user.invitation.organization_name)) {
        initiateConsentAccept()
          .then(() => {
            onContinue();
          })
          .catch((error) => {
            setError(error);
          });
      } else {
        onContinue();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Spinner size="large" />;
  }

  if (!shouldShowConsentPage()) return <></>;

  return (
    <>
      <ConsentContainer config={config} user={user}>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <DenyConsent
                buttonLabel={formatMessage(
                  identityConsentMessages.doNotJoinOrganization
                )}
                onContinue={onContinue}
                onCancel={onCancel}
                user={user}
                config={config}
              />
              <AcceptConsent
                onContinue={onContinue}
                buttonLabel={formatMessage(
                  identityConsentMessages.acceptButton
                )}
                config={config}
                user={user}
                setConsentAccepted={setConsetAccepted}
              />
            </SpaceBetween>
          }
        >
          <SpaceBetween direction="vertical" size="xs">
            <IndetityConsentInfo user={user} />
          </SpaceBetween>
        </Form>
      </ConsentContainer>
    </>
  );
};

export default IdentityConsent;
