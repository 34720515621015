import { AppConfig } from '../types/app';

const setPageStyling = (config: AppConfig): void => {
  if (config.enableCloudScapeVisualRefresh === 'true') {
    document.body.classList.add('awsui-visual-refresh');
  } else {
    document.body.classList.remove('awsui-visual-refresh');
  }
};

export default setPageStyling;
