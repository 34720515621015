import { useEffect, useRef } from 'react';
import { PostAuthComponentParams } from '../../types/auth';
import { request } from '../../utils/apiHelper';
import { GANDALF_SESSION } from '../../constants/metrics';
import { isStandardProvider } from '../../constants/providers';

export const GandalfSession = ({
  user,
  onContinue,
  onCancel,
  config,
  setError,
}: PostAuthComponentParams) => {
  const requestInitiated = useRef(false);

  useEffect(() => {
    // Check conditions to skip Gandalf session
    if (
      config.enableGandalfSession !== 'true' ||
      !config.gandalfSessionEndpoint ||
      !user.jwtToken ||
      isStandardProvider(user.providerName)
    ) {
      onContinue();
      return;
    }

    if (requestInitiated.current) return; // Skip if request already initiated
    requestInitiated.current = true;

    const initiateGandalfSession = async () => {
      try {
        // Make the POST request
        const response = await request(
          config.gandalfSessionEndpoint!,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            credentials: 'include',
            body: new URLSearchParams({
              token: user.jwtToken!,
            }),
          },
          {
            metricNs: GANDALF_SESSION,
          }
        );

        // Check if response status is 200 before calling onContinue
        if (response.status === 200) {
          onContinue();
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(
          `Error posting the Gandalf Session: ${(error as Error).message}`
        );
        setError(error);
      }
    };

    initiateGandalfSession();
  }, [
    config.enableGandalfSession,
    config.gandalfSessionEndpoint,
    onContinue,
    setError,
    user.jwtToken,
    user.providerName,
  ]);

  return <></>;
};
